.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-footer {
  background-color: #282c34;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.simpleLink {
  color: #ffffff;
}

.timerDiv {
  border-radius: 10px;
  background-color: #282c34;
  min-width: 80px;
  border-color: white;
  border-width: 2px;
  border-style: solid;
}

.flex {
  display: flex;
}

.tani {
  margin-top: auto;
}

.buttonList {
  display: flex;
  margin-top: 10vh;
}

.button {
  width: 25vw;
  background: transparent;
  color: #c5e1ee;
  border-color: #c5e1ee;
  border-radius: 50vh;
  outline: none;
  font-size: 20px;
  margin: 5px;
  font: bold;
}

@media (min-width: 960px) {
  .button {
    width: 10vw;
  }
}

.button:hover {
  background-color: #5e788375;
}

.image {
  /* Start the shake animation and make the animation last for 0.5 seconds */
  animation: shake 0.5s;

  /* When the animation is finished, start again */
  animation-iteration-count: infinite;
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}
